import React from "react"
import { DocumentDownloadIcon, DocumentIcon } from "@heroicons/react/outline"
// import { Link } from "gatsby"

import Header from "./home/_header"

function Terms(props) {
  const pathname = props.location.pathname
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      {/*  Site header */}
      <Header pathname={pathname} />

      {/*  Page content */}
      <main className="flex-grow">
        <section className="bg-gradient-to-b from-gray-100 to-white">
          <div className="max-w-6xl mx-auto px-4 sm:px-6">
            <div className="pt-32 pb-12 md:pt-40 md:pb-20">
              {/* Page header */}
              <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
                <h1 className="h1">
                  Criteria and <br />
                  terms &amp; conditions
                </h1>
              </div>
              <ol className="list-decimal space-y-4">
                <li className="ml-4">
                  <p className="ml-2 text-xl text-left">
                    University Tuition Fees per student bills, applicable for
                    courses at Tunku Abdul Rahman University of Management and Technology, Kuala
                    Lumpur Main Campus only.
                  </p>
                </li>
                <li className="ml-4">
                  <p className="ml-2 text-xl text-left">
                    Citizen of Malaysia, born in Sarawak and completed all
                    primary and secondary schooling in Sarawak.
                  </p>
                </li>
                <li className="ml-4">
                  <p className="ml-2 text-xl text-left">
                    Not more than 23 years old at time of application.
                  </p>
                </li>
                <li className="ml-4">
                  <p className="ml-2 text-xl text-left">
                    The scholarship is renewable every semester subject to
                    satisfactory academic performance ( see point. 4 ) and
                    conduct.
                  </p>
                </li>
                <li className="ml-4">
                  <p className="ml-2 text-xl text-left">
                    During the course of study, the scholarship recipient is not
                    allowed to hold concurrently any other form of financial
                    assistance.
                  </p>
                </li>
                <li className="ml-4">
                  <p className="ml-2 text-xl text-left">
                    The tenor of the scholarship is for the normal duration of
                    the completion of the specific degree course.
                  </p>
                </li>
                <li className="ml-4">
                  <p className="ml-2 text-xl text-left">
                    The scholarships carry no bond.
                  </p>
                </li>
              </ol>
            </div>
          </div>
        </section>
      </main>
    </div>
  )
}

export default Terms
